import {
  constructRoutes,
  constructApplications,
  constructLayoutEngine,
} from 'single-spa-layout';
import { registerApplication, start } from 'single-spa';
import { getAppRoutesJson, AppRoutesJson } from './layout';
import { getAccessToken, auth } from '@wework/micro-frontend-utility-auth';

export const configureRoutesAndActivateLayoutEngine = (
  appRoutesJson: AppRoutesJson,
): Promise<void> => {
  const routes = constructRoutes(appRoutesJson);
  const applications = constructApplications({
    routes,
    loadApp: ({ name }) => System.import(name),
  });

  // Delay starting the layout engine until the styleguide CSS is loaded
  const layoutEngine = constructLayoutEngine({
    routes,
    applications,
    active: false,
  });

  // Passing `customProps` is only temporary until SSO Configs has been fully migrated to Mission Control c.f https://jira.weworkers.io/browse/MICO-440
  // https://jira.weworkers.io/browse/MICO-440
  /* eslint-disable no-console */
  applications.forEach((application) => {
    registerApplication({
      name: application.name,
      app: application.app,
      activeWhen: application.activeWhen,
      customProps: {
        getAccessToken,
        notifyClear: (message: string) => {
          console.log(`[notifyClear] name=${application.name} `, message);
        },
        notifyError: (message: string) => {
          window.alert(`[notifyError] name=${application.name} ${message}`);
          console.error(`[notifyError] name=${application.name} `, message);
        },
        notifyInfo: (message: string) => {
          console.log(`[notifyInfo] name=${application.name} `, message);
        },
        notifySuccess: (message: string) => {
          console.log(`[notifySuccess] name=${application.name}`, message);
        },
        notifyWarn: (message: string) => {
          console.warn(`[notifyWarn] name=${application.name}`, message);
        },
        userInfo: {
          uuid: auth.employeeUUID,
        },
        getExperimentGroups: () => ({
          ssBulkContractorUpdate: process.env['BULK_CONTRACTOR_UPDATE_FLAG'],
        }),
      },
    });
  });
  /* eslint-enable no-console */

  // We can load a style guide which configures ray2, tailwind e.t.c and can wait for it to load before anything else
  return System.import('@wework/microsite-styleguide').then(() => {
    // Activate the layout engine once the styleguide CSS is loaded
    layoutEngine.activate();
    start();
  });
};

export async function layoutApp() {
  try {
    const appRoutesJson = await getAppRoutesJson();
    configureRoutesAndActivateLayoutEngine(appRoutesJson);
  } catch (error) {
    // TODO: [MICO-185](https://jira.weworkers.io/browse/MICO-185)
    alert(error);
    // configureRoutesAndActivateLayoutEngine(errorTemplateJson) pass template here.
  }
}
