/*
Changes in this file currently do *not* reflect in preview links
c.f https://jira.weworkers.io/browse/MICO-392
 */

import { login } from '@wework/micro-frontend-utility-auth';
import { layoutApp } from './app';

/**
 * The Mission Control micro-frontend serves as an umbrella framework through which
 * all child micro-frontends can access/import utilities.
 *
 * Export the public contracts of all shared mission control utilities:
 */
export * as Auth from '@wework/micro-frontend-utility-auth';
export * as Ray2 from '@wework/microsite-styleguide';
export * as Routing from '@wework/micro-frontend-utility-routing';

login(process.env.APP_ENV).then((isLoggedIn) => {
  if (isLoggedIn) {
    layoutApp();
    document.getElementById('rootConfigLoadingScreen')?.remove();
  }
});
