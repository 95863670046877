import { getRoutes } from '@wework/micro-frontend-utility-routing';
import './index.css';

interface Attributes {
  name: string;
  value: string;
}

interface Props {}

export interface Route {
  type: string;
  name?: string;
  loader?: string;
  error?: string;
  value?: string;
  default?: boolean;
  path?: string;
  props?: Props;
  attrs?: Attributes[];
  routes?: Route[];
}

export interface AppRoutesJson {
  routes: Route[];
}

/**
 * @summary Loads sidebar microfrontend in a div with class sidebar.
 * @returns {Route}
 */
const navigationRoute: Route = {
  type: 'div',
  attrs: [{ name: 'class', value: 'sidebar' }],
  routes: [
    {
      type: 'application',
      name: '@wework/micro-frontend-app-navigation-bar',
      error: `
        <div class="navBar"> 
        <div class="textContainer">
        <div class="errorText">ERROR</div>
        <div class="headingText">Issue Loading<br>Navigation Bar</div>
        <div class="bodyText">Try refreshing the page or slack us at: <br> 
        <a class="slackLink" href="https://app.slack.com/client/T03KB9Y6Y/C03EPL6TGP5/" target="_blank" rel="noreferrer noopener">
        #mission-control-general
        </a>
        </div> 
        </div>
        </div>`,
    },
  ],
};

/**
 * @summary If no micro-frontend is renders on the url then this div renders as default.
 * @returns {Route}
 */
const defaultRoute: Route = {
  type: 'route',
  default: true,
  routes: [
    {
      type: 'application',
      name: '@wework/micro-frontend-app-default',
    },
  ],
};

/**
 * @summary Fetch microFrontendRoutes from mission-control-routing(getRoutes) & prep routes json template.
 * Json structure doc: https://single-spa.js.org/docs/layout-definition/#json-dom-nodes.
 * getRoutes can throw an error if OperatorService service fails.
 * prepMicroFrontendRoutes catches error, shows an alert & resolves the promise with an empty array.
 * @returns {Promise<Route[]>}
 */
const prepMicroFrontendRoutes = async (): Promise<Route[]> => {
  try {
    const microFrontends = await getRoutes();
    return microFrontends.map(({ name, path }): Route => {
      return {
        type: 'route',
        path,
        routes: [{ type: 'application', name }],
      };
    });
  } catch (error) {
    throw Error(`${error}`);
  }
};

/**
 * @summary prepares a page-container div which holds the microFrontendRoutes and default route
 * getMicroFrontendRoutes can throw an error if prepMicroFrontendRoutes service fails.
 * @returns {Promise<Route>}
 */
const getMicroFrontendRoutes = async (): Promise<Route> => {
  try {
    const microFrontendRoutes = await prepMicroFrontendRoutes();
    return {
      type: 'div',
      attrs: [{ name: 'class', value: 'page-content p-8 w-full' }],
      routes: [...microFrontendRoutes, defaultRoute],
    };
  } catch (error) {
    throw Error(`${error}`);
  }
};

/**
 * @summary preps the main div container. Adds navigation & microFrontend routes inside the main div as routes.
 * getAppRoutesJson can throw an error if getMicroFrontendRoutes service fails.
 * @returns {Promise<AppRoutesJson>}
 */
export const getAppRoutesJson = async (): Promise<AppRoutesJson> => {
  try {
    const microFrontendRoutes = await getMicroFrontendRoutes();
    return {
      routes: [
        {
          type: 'div',
          attrs: [{ name: 'class', value: 'main font-main' }],
          routes: [navigationRoute, microFrontendRoutes],
        },
      ],
    };
  } catch (error) {
    throw Error(`${error}`);
  }
};
